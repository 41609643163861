body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

.underline {
  text-decoration: underline;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
